
import { defineComponent, inject } from 'vue';
import { AuthKey } from '@/symbols';

export default defineComponent({
  name: 'login',
  inject: ['Auth'],
  methods: {
    login: function (): any {
      (this as any).Auth.loginWithRedirect();
    },
  },
  setup() {
    const auth = inject('Auth') as typeof AuthKey;
    return {
      ...auth,
    };
  },
});
